<template>
  <div class="overview">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>资金概览</a-breadcrumb-item>
      <a-breadcrumb-item href="">
        充值
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div class="card-container">
      <a-tabs type="card">
        <a-tab-pane key="1" tab="线下汇款充值">

          <div class="common-tip mb-20">线下汇款账号</div>
          <div class="tips">
            <a-icon type="exclamation-circle" />
            请您通过网银转账或自行到银行进行汇款
          </div>
          <a-form-model :label-col="labelCol" :model="formInfo" :wrapper-col="wrapperCol">
            <a-form-model-item label="开户行名称">
              <a-input v-model="formInfo.accountBankName" class="tips-input-width" disabled />
            </a-form-model-item>
            <a-form-model-item label="开户行">
              <div style="display: flex;align-items: center;">
                <div class="formImg" style="margin-right: 10px">
                  <img :src="formInfo.accountImg" alt="">
                </div>
                <div>
                  <a-input v-model="formInfo.accountBank" class="tips-input-widths" disabled />
                </div>
              </div>
            </a-form-model-item>
            <a-form-model-item label="银行卡账号">
              <a-input v-model="formInfo.accountBankNumber" class="tips-input-width" disabled />
            </a-form-model-item>
          </a-form-model>

          <div class="common-tip mb-20">汇款后请填写您汇款的金额及银行交易流水号</div>
          <a-form-model
            ref="ruleForm"
            :label-col="labelCol"
            :model="form"
            :rules="rechargeRules"
            :wrapper-col="wrapperCol">
            <a-form-model-item label="交易流水号" prop="dealNumber">
              <a-input v-model="form.dealNumber" :maxLength="64" class="tips-input-width" />
            </a-form-model-item>
            <a-form-model-item label="金额" prop="topUpAmount">
              <a-input v-model="form.topUpAmount" addon-before="￥" class="tips-input-width" @input="numPrice($event)" />
            </a-form-model-item>
            <a-form-model-item label="汇款底单" prop="remitImg" :wrapper-col="{ span: 12, offset: 0 }">
              <file-upload v-model="form.remitImg" :value="uploadImgUrl"></file-upload>
              <p class="upload-txt">
                汇款单底需清晰可见，照片内容真实有效，不得做任何修改支持 .jpg/.jpeg/.bmp/.gif/.png格式图片，大小不超过1M
              </p>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 6, offset: 2 }">
              <a-button style="background-color: #1890ff;border-color: #1890ff;" type="primary" @click="submit">
                我已汇款
              </a-button>
            </a-form-model-item>
          </a-form-model>

          <div class="common-tip common-tip2 mb-20">温馨提示</div>
          <div>
            <p class="warm-txt">
              1、请您使用绑定的银行账户通过网银转账或线下银行网点进行汇款充值。
            </p>
            <p class="warm-txt">
              2、线下汇款至对公账户，不同银行到账时间不一，如招行1-2天， 跨行3-5天（以银行实际到账时间为准）。
            </p>
            <p class="warm-txt">
              3、提交汇款充值信息后，将在24小时内完成充值。如未到账，请咨询：400-610-1616。
            </p>
          </div>

        </a-tab-pane>
      </a-tabs>
    </div>

  </div>
</template>

<script>
import { recharge, information } from '@/api/finance/stream'
import { rechargeRules, wrapperCol, labelCol } from './iconImg'
import { limitNumTool } from '@/utils/utils'

export default {
  name: 'Recharge',
  data () {
    return {
      rechargeRules,
      labelCol,
      wrapperCol,
      formInfo: {
        accountBank: '',
        accountBankName: '',
        accountBankNumber: ''
      },
      form: {
        'createOrUpdate': 0,
        'createTime': null,
        'dealNumber': null,
        'endTime': null,
        'payerName':null,
        'rates': 0,
        'remitImg': null,
        'serviceCharge': 0,
        'startTime': null,
        'topUpAmount': null ,
        'topUpNumber': null,
        'topUpState': 3,
        'topUpTime': null,
        'topUpType': 3,
        'updateTime': null,
        'userId': 0,
        'userName': null
      },
      loading: false,
      imageUrl: '',
      uploadImgUrl:''
    }
  },
  created () {
    this.getInfo()
    console.log('打印',process.env.VUE_APP_API_BASE_URL+'/common/upload')
  },
  mounted(){
    this.uploadImgUrl = process.env.VUE_APP_API_BASE_URL+'/common/upload'
  },
  methods: {
    numPrice(value) {
      this.form.topUpAmount = limitNumTool(value.target.value, 24)
    },
    getInfo () {
      information().then(res => {
        console.log('res', res)
        this.formInfo = res.data
      })
    },
    callback (key) {
      console.log(key)
    },
    submit () {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.form.accountBank = this.formInfo.openAnAccount
          this.form.bankNumber = this.formInfo.bankNumber
          const res = await recharge(this.form)
          console.log('充值成功res', res)
          this.$message.success('充值提交成功，等待审核！', 3)
          // 跳到充值记录页面
          this.$router.push({
            path: '/user/recharge'
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.ant-form-item ::v-deep .ant-col-2 {
  width: 9.33333333%;
}
.card-container {
  margin-top: 15px;
}
.tips-input-widths{
  width: 363px;
}
.tips-input-width{
  width: 500px;
}
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

:deep(.ant-tabs-bar) {
  margin: 0 !important;
  border: none !important;
}

:deep(.ant-tabs-tab) {
  border: none !important;
}

.tips {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 14px;
  color: #ffac28;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload-txt {
  font-size: 12px;
  line-height: 18px;
  color: #999;
}

</style>
